import React from 'react'
import { Link } from 'gatsby'
import { Button } from 'theme-ui'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Section from '@components/Section'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import Heading from 'theme-ui'


const styles = {
  count: {
    fontSize: 4
  },
  subheader: {
    fontWeight: `body`,
    color: `omegaDark`
  },
  runninghead: {
    fontWeight: `body`,
    color: `omegaDark`,
    mb: 0
  }
}

const Impressum = props => (
  <Layout {...props}>
    <Seo title='Impressum' />
    <Divider />
    <Stack>
      <Main>
        <Section>
          <PageTitle
            header="Impressum"
        
          />
        </Section>
        <Section>
        
        <h1>Verantwortlich für den Inhalt</h1>
        Arbeitsgruppe Schulraumplannung

        <h1>Webdesign</h1>
        Marc Herren

        <h1>Hosting</h1>
        BEGASOFT AG

        </Section>
      </Main>
    </Stack>
  </Layout>
)

export default Impressum
